<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="#FDCE48"
      spinner="bar-fade-scale"
    />
    <v-snackbar v-model="showSnackBar" color="#68D389" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">
          <span style="color: #ffffff">
            {{ msg }}
          </span>
        </v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: #ffffff">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap justify-end>
      <v-flex xs12 sm7 md7 lg7 xl7 text-left px-2>
        <span style="font-size: 20px; font-family: poppinsbold">
          Notification
        </span>
      </v-flex>
      <v-flex xs6 sm4 md4 lg4 xl4 pr-2 text-right align-self-center>
        <span style="font-weight: bold">
          <span>Display Notification </span>
        </span>
      </v-flex>
      <v-flex xs6 sm1 md1 lg1 xl1 pl-2 text-right align-self-center>
        <v-switch
          v-model="notificationData.notificationStatus"
          :ripple="false"
          color="#68D389"
          hide-details
        >
          <template v-slot:label>
            <span style="font-weight: bold">
              <span v-if="notificationData.notificationStatus"> Yes </span>
              <span v-else> No </span>
            </span>
          </template>
        </v-switch>
      </v-flex>
      <v-flex xs12 sm6 md6 lg6 xl6 pa-2 pt-8>
        <v-textarea
          v-model="notificationData.heading"
          name="input-7-1"
          label="Heading"
          rows="3"
          outlined
        ></v-textarea>
      </v-flex>
      <v-flex xs12 sm6 md6 lg6 xl6 pa-2 pt-8>
        <v-textarea
          v-model="notificationData.description"
          name="input-7-1"
          label="Description"
          rows="3"
          outlined
        ></v-textarea>
      </v-flex>
      <v-flex xs12 sm6 md3 lg3 xl3 text-right>
        <v-btn
          block
          tile
          dark
          color="#68D389"
          light
          :ripple="false"
          depressed
          @click="editNotification"
          class="itemValue"
        >
          Save Changes
        </v-btn>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      notificationData: {
        heading: null,
        description: null,
        notificationStatus: false,
        id: null,
      },
      appLoading: false,
      showSnackBar: false,
      timeout: 5000,
      msg: null,
    };
  },
  mounted() {
    this.getData();
  },

  methods: {
    getData() {
      this.appLoading = true;
      axios({
        url: "/appNotification/view",
        method: "GET",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          if (response.data.status) {
          this.appLoading = false;
          this.notificationData = response.data.data;
          }
          else{
            this.appLoading = false;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    editNotification() {
      var data = {};
      data["heading"] = this.notificationData.heading;
      data["description"] = this.notificationData.description;
      data["notificationStatus"] = this.notificationData.notificationStatus;
      axios({
        url: "/appNotification/edit/" + this.notificationData._id,
        method: "POST",
        data: data,
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          if (response.data.status) {
            this.msg = response.data.msg;
            this.showSnackBar = true;
            this.getData();
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>